import classNames from "classnames"
import React from "react"

import ProductPresentationBase from "../../../components/_V2/ProductPresentationBase"

import data from "./data"
import styles from "./styles.module.scss"

interface Props {
	className?: string
	leftColumn?: string
	title?: string
}

export default function ProductPresentation(props: Props) {
	return (
		<ProductPresentationBase
			sectionClass={styles.section}
			containerClass={styles.content}
			data={data}
			backdropClass={styles.backdrop}
			backdropMobileClass={styles.backdropMobile}
			className={props.className}
			additionalTitleClass={classNames(styles.title, props.title)}
			additionalLeftColumn={props.leftColumn}
		/>
	)
}
