import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 555,
			tabName: "Аналитика",
			desktopTitle: "Онлайн система управления ресторана суши",
			desktopContent: (
				<p>
					Система автоматизации ресторана суши Quick Resto позволяет отслеживать
					и анализировать все процессы работы: от приёмки товаров на точке
					продаж до определения самых продаваемых позиций и расчёта зарплат
					сотрудников.
				</p>
			),
			detailLink: pagesLinks.reports,
			mediaContent: (
				<StaticImage
					className={mediaContent.image1}
					src="./assets/sushi-accounting-1.png"
					alt="система автоматизации ресторана суши"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 555,
			tabName: "Склад",
			desktopTitle: "Складские операции на кассовом терминале",
			desktopContent: (
				<p>
					В программе учёта Quick Resto можно проводить складские операции прямо
					с кассового терминала: доверь персоналу создание приходных накладных и
					проведение инвентаризаций. Все результаты будут доступны в облачном
					бэк-офисе с любого устройства.
				</p>
			),
			detailLink: pagesLinks.warehouse,
			mediaContent: (
				<StaticImage
					className={mediaContent.image2}
					src="./assets/sushi-accounting-2.png"
					alt="складской учет в суши баре"
					objectFit="cover"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 555,
			tabName: "Отчеты",
			desktopTitle: "Увеличивай средний чек и свою прибыль",
			desktopContent: (
				<p>
					Отчеты по продажам покажут, какие блюда приносят больше прибыли
					ресторану суши. Отчеты по сотрудникам дадут возможность увидеть, кто
					продаёт лучше. Автоматический расчет зарплат добавит прозрачности и
					мотивации для вашего персонала.
				</p>
			),
			detailLink: pagesLinks.reports,
			mediaContent: (
				<StaticImage
					className={mediaContent.image3}
					src="./assets/sushi-accounting-3.png"
					alt="abc-анализ"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	],
}
