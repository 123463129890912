import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styles from "./styles.module.scss"
import loyaltyStyles from "../common/loyalty-feature/styles.module.scss"
import indicatorsStyles from "../common/indicators-feature/styles.module.scss"
import implementationStyles from "../common/implementation-feature/styles.module.scss"

import { pagesLinks } from "../../common/links"

export default [
	{
		title: "Облачная программа автоматизации для ресторана суши",
		description: (
			<>
				<p>
					Управление продажами в ресторанах суши и отслеживание финансовых
					результатов теперь не просто удобно, с Quick Resto это ещё и
					современно и технологично.
				</p>
				<p data-mt="big">
					Единая экосистема объединяет в себе интуитивно понятный кассовый
					терминал на планшете, мобильное приложение с функциями предзаказа и
					push-уведомлений, личный кабинет с полной статистикой продаж и
					аналитикой за прошедшие периоды, система электронной очереди и
					QR-меню.
				</p>
			</>
		),

		img: (
			<StaticImage
				className={styles.image1}
				src="./assets/sushi-feature-1.png"
				alt="программа для доставки суши"
				breakpoints={[500, 670, 790, 630]}
				sizes="(max-width: 500px) 440px, (max-width: 800px) 670px, (max-width: 1319px) 790px, (min-width: 1320px) 630px"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,
		className: styles.feature1,
		theme: "white",

		backdrop: <div className={styles.featureTopLeftTrapezeBackdrop} />,
	},
	{
		title: "Приём различных типов оплат",
		description: (
			<p className={styles.smallDescription}>
				Оплата наличными или картой в несколько действий и последующий
				корректный учёт это именно то, чего так не хватало малым форматам
				бизнеса и это то, что предлагает программа Quick Resto.
			</p>
		),

		img: (
			<StaticImage
				className={styles.image2}
				src="./assets/sushi-feature-2.png"
				alt="кассовая система для суши бара"
				breakpoints={[440, 670, 800, 564]}
				sizes="(max-width: 500px) 440px, (max-width: 800px) 670px, (max-width: 1319px) 800px, (min-width: 1320px) 564px"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,
		className: styles.feature2,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.terminal.href,
	},
	{
		title: "Удобная система управления \nдля суши-бара",
		description: (
			<p className={styles.smallDescription}>
				Расширяй возможности бизнеса
				с&nbsp;дополнительными приложениями
				и&nbsp;оборудованием: подключи тикет-принтер
				для кухни, дисплей повара и&nbsp;дисплей покупателя,
				а&nbsp;также экран электронной очереди.
			</p>
		),

		img: (
			<StaticImage
				className={styles.image3}
				src="./assets/sushi-feature-3.png"
				alt="crm для суши бара"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: styles.feature3,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.marketing.href,
	},
	{
		title: "Электронное меню для суши-бара",
		description: (
			<p>
				За один день запустим мобильный сайт с меню для суши-бара. Гости будут
				попадать в него по QR-коду, который можно разместить у кассы, на входной
				двери, столиках или в уголке потребителя. Добавь ссылку на электронное
				меню в соцсети, карты Яндекса, Google и 2ГИС, чтобы захватывать больше
				новых посетителей.
			</p>
		),

		img: (
			<StaticImage
				className={styles.image4}
				src="./assets/sushi-feature-4.png"
				alt="электронное меню для суши бара"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,
		className: styles.feature4,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.wlShop.href,
	},
	{
		title: "Программа лояльности, как инструмент для привлечения гостей",
		description: (
			<p>
				Запускай акции на нужные позиции блюд, настраивай предложения на
				комбо-наборы и скидки по времени. Используя возможности программы для
				доставки суши и мобильного приложения для гостей, отправляй персональные
				предложения: сегментируй аудиторию по чекам и настраивай
				push-уведомления.
				<br />
				<br />
				Гости смогут оформлять предзаказы и доставку, следить в приложении за
				уровнем бонусов и текущими акциями.
			</p>
		),

		img: (
			<StaticImage
				className={loyaltyStyles.image}
				src="../common/loyalty-feature/loyalty-feature.png"
				alt="программы лояльности для суши ресторана"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,

		className: loyaltyStyles.feature,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.marketing.href,
	},
	{
		title: "Показатели всегда под рукой",
		description: (
			<p className={styles.smallDescription}>
				Используйте мобильное приложение Quick&nbsp;Resto Manager для быстрого
				удаленного доступа к статистике работы заведения. Вы сможете оценить
				эффективность программ лояльности, работу сотрудников, прибыльность блюд
				и многое другое.
			</p>
		),

		img: (
			<StaticImage
				className={indicatorsStyles.image}
				src="../common/indicators-feature/indicators-feature.png"
				alt="quick resto manager"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: indicatorsStyles.feature,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.indexQRM.href,
	},
	{
		title: "Внедрение автоматизации ресторана суши под ключ",
		description: (
			<>
				<p>От первой продажи до масштабирования.</p>
				<p>Quick Resto даст все инструменты:</p>
				<ul>
					<li>Приложения для управления и продаж</li>
					<li>Отчёты по продажам, блюдам, сотрудникам</li>
					<li>Складской учёт и технологические карты</li>
					<li>CRM-система и аналитика</li>
					<li>Сервис: установка, настройка, обучение</li>
					<li>Круглосуточная техподдержка</li>
				</ul>
			</>
		),

		img: (
			<StaticImage
				className={implementationStyles.image}
				src="../common/implementation-feature/implementation-feature.png"
				alt="Алексей Гаврилов"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: implementationStyles.feature,
		theme: "white",
		backdrop: (
			<div className={implementationStyles.featureTopRightTrapezeBackdrop} />
		),
	},
]
