import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 540,
			tabName: "Интерфейс",
			desktopTitle: "Терминал, продуманный до мелочей",
			desktopContent: (
				<>
					<p>
						На кассовом терминале простая навигация и подсказки для кассиров. А
						для руководителя — возможность планировать продажи.
					</p>
					<p data-mt="small">
						Программа позволяет контролировать актуальность меню. Сделай на
						терминале папку с новинками или высокомаржинальными блюдами.
						Персонал в начале смены проверит план и будет предлагать гостям то,
						что вам более выгодно продать. Так можно снизить потери продуктов
						и&nbsp;увеличить эффективность работы.
					</p>
				</>
			),
			detailLink: pagesLinks.terminal,
			mediaContent: (
				<StaticImage
					className={mediaContent.image1}
					src="./assets/sushi-interface-1.png"
					alt="кассовое оборудование для суши ресторана"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 540,
			tabName: "Повару",
			desktopTitle: "Кухонный экран повара",
			desktopContent: (
				<p>
					Благодаря автоматизации ресторана суши у поваров на кухне есть свой
					терминал — Kitchen Display System для обратной связи с&nbsp;кассиром.
					Повар может оставить комментарий к заказу, если что-то пошло не так. И
					отметить готовность блюда — кассир увидит новый статус заказа на своём
					кассовом терминале.
				</p>
			),
			detailLink: pagesLinks.indexKDS,
			mediaContent: (
				<StaticImage
					className={mediaContent.image2}
					src="./assets/sushi-interface-2.png"
					alt="кухонный экран повара"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	],
}
